<template >
  <v-container fluid>
    <v-row justify="center" no-gutters>
      <v-col align="center">
        <v-col v-if="getDataStates === 600" cols="4" class="pa-0">
          <v-text-field
            label="Order Id"
            @change="searchOrder"
            v-model="orderId"
          ></v-text-field>
        </v-col>
        <v-col v-else cols="4" class="pa-0">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>

        <v-row no-gutters>
          <v-col cols="3">
            <div>
              <h3 class="pa-2 d-flex justify-space-between">
                <v-btn text icon color="red lighten-2">
                  <v-icon color="red darken-2" @click="emptyList">
                    mdi-delete
                  </v-icon>
                </v-btn>
                Pistoleados
                <v-btn text icon>
                  <v-icon color="blue darken-2" @click="copiarLista">
                    mdi-content-copy
                  </v-icon>
                </v-btn>
              </h3>
            </div>
            <v-divider></v-divider>
            <div class="mt-3 mx-5">
              <ol>
                <li v-for="orderId in scanOrderIds" :key="orderId">
                  {{ orderId }}
                </li>
              </ol>
            </div>
          </v-col>
          <v-col col="1"></v-col>
          <v-col v-if="!loadingData" cols="8">
            <v-data-iterator
              class="mx-3"
              :items="orderIdInfo"
              hide-default-footer
            >
              <template v-slot:default="props">
                <v-col
                  align-self="center"
                  v-for="item in props.items"
                  :key="item.name"
                  cols="12"
                >
                  <v-card>
                    <v-toolbar color="#311def" dark
                      >Order Id: {{ item.order_id_task || "" }}</v-toolbar
                    >

                    <v-row
                      align="center"
                      no-gutters
                      style="background-color: #cbdffd"
                    >
                      <v-col cols="9">
                        <h1 class="text-h2 py-2">
                          {{
                            item.zone_name ? item.zone_name : "NO ZONIFICADO"
                          }}
                        </h1>
                        <h1 class="text-h3 py-3">
                          {{
                            item.current_route
                              ? `Ruta ` + getRoute(item.current_route)
                              : "NO ASIGNADO A RUTA"
                          }}
                        </h1>
                        <h4 class="subtitle-1">
                          Dirección: {{ item.address_task || "" }}
                        </h4>
                        <h4>
                          Cliente: {{ getCustomer(item.idcustomer_task || "") }}
                        </h4>
                      </v-col>
                      <v-col cols="3">
                        <div class="pa-3">
                          <v-img
                            height="100%"
                            :src="getCustomerImage(item.idcustomer_task) || ''"
                          ></v-img>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </template>
            </v-data-iterator>
          </v-col>
          <v-col v-else cols="8">
            <v-progress-circular
              :size="70"
              :width="7"
              color="blue"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" color="success" :timeout="2000">
      Lista copiada!
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs"> Cerrar </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import firebase from "firebase";

export default {
  data() {
    return {
      getDataStates: 0,
      orderId: "",
      orderIdInfo: [],
      zones: null,
      routes: null,
      customers: null,
      orderIdArray: [],
      scanOrderIds: null,
      snackbar: false,
      loadingData: false,
    };
  },
  methods: {
    datosUsuario () {
      // console.log(firebase.auth())
      return {
        email_user: firebase.auth().currentUser.email,
        name_user: firebase.auth().currentUser.displayName,
        uid: firebase.auth().currentUser.uid
      }
    },
    async getCustomers() {
      try {
        const result = await axios.get(
          "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/customers"
        );
        console.log("CUSTOMERS", result.status);
        this.getDataStates += result.status;
        this.customers = result.data;
      } catch (error) {
        alert("Error obteniendo clientes! Comunícate con TI Rayo");
      }
    },
    async getZones() {
      try {
        const result = await axios.get(
          "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/zones"
        );
        console.log("ZONES", result.status);
        this.getDataStates += result.status;
        this.zones = result.data;
      } catch (error) {
        alert("Error obteniendo las zonas! Comunícate con TI Rayo");
      }
    },
    async getRoutes() {
      try {
        const result = await axios.get(
          "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/routes"
        );
        console.log("ROUTES", result.status);
        this.getDataStates += result.status;
        this.routes = result.data;
      } catch (error) {
        alert("Error obteniendo las rutas! Comunícate con TI Rayo");
      }
    },
    getRoute(id) {
      return this.routes.filter((route) => route._id === id)[0].id;
    },
    getCustomer(id) {
      return this.customers.filter((customer) => customer._id === id)[0]
        .name_customer;
    },
    getCustomerImage(id) {
      return this.customers.filter((customer) => customer._id === id)[0]
        .image_customer;
    },
    emptyList() {
      localStorage.removeItem("OrderIds");
      this.scanOrderIds = null;
      this.orderIdArray = [];
    },
    getLocalStorage() {
      this.scanOrderIds = JSON.parse(localStorage.getItem("OrderIds"));
    },
    localStorageOrders() {
      let findData = JSON.parse(localStorage.getItem("OrderIds"));
      findData
        ? (this.orderIdArray = JSON.parse(localStorage.getItem("OrderIds")))
        : (this.orderIdArray = []);
      this.orderIdArray.push(this.orderId);
      localStorage.setItem("OrderIds", JSON.stringify(this.orderIdArray));
    },
    async copiarLista() {
      try {
        this.snackbar = false;
        let lista = JSON.stringify(this.scanOrderIds);
        await navigator.clipboard.writeText(lista);
        this.snackbar = true;
      } catch (err) {
        alert("Error copiando texto: ", err);
      }
    },
    async searchOrder() {
      this.loadingData = true;
      try {
        if (this.orderId === "") {
          throw new Error("No hay datos para buscar!");
        }

        let data = {
          orderId: this.orderId,          
          emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime()
        };

        const result = await axios.post(
          "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/multiscan",
          data
        );

        if (result.data.dataFound === 1) {
          let infoPedido = result.data;

          this.orderIdInfo = [
            {
              order_id_task: infoPedido.order_id_task,
              zone_name: infoPedido.zone_name,
              current_route: infoPedido.current_route,
              address_task: infoPedido.address_task,
              idcustomer_task: infoPedido.idcustomer_task,
            },
          ];
        } else {
          this.orderIdInfo = [
            {
              order_id_task: "",
              zone_name: "",
              current_route: "",
              address_task: "",
              idcustomer_task: "",
            },
          ];
          alert("Pedido no se encuentra en THOR!");
        }

        this.localStorageOrders();
        this.getLocalStorage();
        this.orderId = "";
      } catch (error) {
        let errorData = error.response ? error.response.data : error;
        alert("Error! " + errorData);
        this.loadingData = false;
      }
      this.loadingData = false;
    },
  },
  mounted() {
    this.getZones();
    this.getRoutes();
    this.getCustomers();
    this.getLocalStorage();
  },
};
</script>
